import classNames from 'classnames';
import SectionTitle from 'UI/components/SectionTitle';
import { getDocumentFields } from 'utils/helper';
import { get } from 'lodash';
import Svg from 'UI/components/Svg';
import styles from './styles.module.scss';

const SvgList = ({
  section,
  data,
  type,
}) => {
  const {
    title,
    description,
    view,
    contentModules,
  } = getDocumentFields(
    data || section,
    [
      'title',
      'description',
      'contentModules',
      'view',
    ],
  );
  const { contentList: svgList } = getDocumentFields(get(contentModules, '[0]', {}), ['contentList']);

  return (
    <section className={classNames(styles.section, styles[type], styles[view])}>
      <SectionTitle
        title={title}
        description={description}
        titleStyle={styles.titleStyle}
      />
      <div className={styles.container}>
        {(svgList || []).map((item) => (
          <Svg
            key={item}
            type={item}
            className={styles.svg}
          />
        ))}
      </div>
    </section>
  );
};

export default SvgList;
